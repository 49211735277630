import "./App.scss";
import Home from "./pages/home/Home";
import { Routes, Route, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../src/assets/css/animate.min.css";
import OurSolutions from "./pages/ourSolutions/OurSolutions";
import ContactUs from "./pages/contactUs/ContactUs";
import FAQ from "./pages/faqs/FAQ";
import BookADemo from "./pages/bookADemo/BookADemo";
import { Fragment, useEffect, useState } from "react";
import FinancialInstitution from "./pages/financialInstitution/FinancialInstitution";
import Clients from "./pages/clients/Clients";
import Privacy from "./pages/privacy/Privacy";
import Policy from "./pages/policy/Policy";
import ErrorPage from "./pages/error/ErrorPage";
import CompanyDetails from "./pages/companiesDetail/CompanyDetails";
import Mecred from "./pages/mecred/Mecred";
import Creset from "./pages/creset/Creset";
import Deftra from "./pages/deftra/Deftra";
import MecredPrivacyPolicy from "./pages/mecredprivacypolicy/MecredPrivacyPolicy";
import MecredCommingSoon from "./pages/MecredCommingSoon/MecredCommingSoon";

import CookieConsent from "react-cookie-consent";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

function App() {
  const [cookieModel, setCookieModel] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/aboutus" element={<Home />} /> */}
        <Route path="/oursolutions" element={<OurSolutions />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/bookademo" element={<BookADemo />} />
        <Route path="/solutions/businesses" element={<Clients />} />
        <Route
          path="/solutions/institution"
          element={<FinancialInstitution />}
        />
        <Route path="/operatingcompany/:id" element={<CompanyDetails />} />

        <Route path="/mecred/privacy-policy" element={<MecredPrivacyPolicy />} />

        <Route path="/creset" element={<Creset />} />
        <Route path="/deftra" element={<Deftra />} />

        {/* <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Policy />} /> */}

        <Route path="/mecred" element={<Mecred />} />
        {/* <Route path="/mecred" element={<MecredCommingSoon />} /> */}

        <Route path="*" element={<ErrorPage />} />
      </Routes>

      <CookieConsent
        location="bottom"
        buttonText="Allow All Cookies"
        enableDeclineButton
        onDecline={() => {
          setCookieModel(true);
        }}
        declineButtonText="Decline"
        cookieName="myCookie"
        style={{ background: "#041d2c", fontSize: "18px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
        <br />
        <span style={{ fontSize: "16px" }}>
          We use cookies to personalise content and ads, to provide social media
          features and to analyse our traffic. We also share information about
          your use of our site with our social media, advertising and analytics
          partners who may combine it with other information that you’ve
          provided to them or that they’ve collected from your use of their
          services.
        </span>
      </CookieConsent>
      <Modal
        show={cookieModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cookie_model"
      >
        <Modal.Body style={{ padding: "0px" }}>
          <section className="policy_page_landing">
            <div className="content_wrap">
              <button onClick={() => setCookieModel(false)} className="close">
                <IoMdClose />
              </button>
              <h3 className="gradient dark">Cookie Policy</h3>
            </div>
          </section>
          <section className="policy_page_content">
            <div className="content_wrap p_b">
              <h4 className="gradient">Effective Date : May 2<sup>nd</sup>, 2024 </h4>
              <h4 className="gradient" style={{ marginTop: "0px" }}>
                What are cookies?
              </h4>
              <p>
                Cookies are small text files which are downloaded to the browser
                directory of your computer or mobile device when you visit a
                website, to collect standard Internet log information and
                visitor behavior information. They are widely used in order to
                make websites work, or work more efficiently, as well as to
                provide information to the owners of the website. When you visit
                our website, we may collect information from you automatically
                through cookies or similar technology. For further information
                about cookies, click here.
              </p>

              <h4 className="gradient">How do we use cookies?</h4>
              <p>
              MeCred (DIFC) Ltd uses cookies in a range of ways to
                improve your experience on our digital platform <b>BeMyCred</b>,
                including to keeping you signed in.{" "}
              </p>

              <h4 className="gradient">What types of cookies do we use?</h4>
              <p>
                There are a number of different types of cookies, and our
                website uses the following:{" "}
              </p>

              {/* Tri Sec Info */}
              <div className="tri_sec">
                <div className="col_sec">
                  <p className="t-20">Cookie</p>
                  <p className="text b_t">Access token </p>
                  <p className="text">cookie_notice_accepted </p>
                </div>
                <div className="col_sec">
                  <p className="t-20">Purpose </p>
                  <p className="text b_t">For keeping you signed in </p>
                  <p className="text">Acceptance of cookie usage </p>
                </div>
                <div className="col_sec">
                  <p className="t-20">Expiry</p>
                  <p className="text b_t">1 month </p>
                  <p className="text">1 month</p>
                </div>
              </div>

              <h4 className="gradient">How to manage your cookies?</h4>
              <p>
                Most browsers allow you to manage if and/or how cookies are set
                and used, and to clear cookies and browser data. Your browser
                may have settings that let you manage cookies on a site-by-site
                basis. Please refer to your browser instructions to learn more
                about your options for managing cookies.
              </p>

              <h4 className="gradient">Cookie policy update</h4>
              <p>
                We reserve the right to apply any amendments or rectifications
                to this notice. Please visit this page from time to time to
                check any new or additional information. We will publish any
                updates on the website and, otherwise, we will inform about any
                changes by email.
              </p>

              <h4 className="gradient">Privacy Policy</h4>
              <p>
                For more information on how we use your personal data, please{" "}
                <NavLink to="/mecred/privacy-policy" onClick={() => setCookieModel(false)}>click here.</NavLink>
              </p>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default App;
